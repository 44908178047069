<template>
  <div class="container py-5">
    <p class="fw-bold">2022 WRG祛斑抗衰高峰巡迴論壇(鄭州站)</p>
    <p>時間：2022 08/24-25</p>
    <p>地點：鄭州 ‧ 永和鉑爵國際酒店三樓</p>
    <p>
      WRG（WHITENING AND REJUVENATION GROUP）祛斑抗衰聯盟是由一批長期工作在臨床一線的實操型醫美專家組成的學術聯盟，旨在運用聯盟成員的集體智慧，研究解決美白祛斑和抗衰年輕化的整合治療方案，力求效果最大化和副反應最小化。
      <br><br>
      本次WRG集業界內數百名精英齊聚一堂，凝聚國內抗衰老祛斑美膚先進理念與技術精髓，助力醫美行業多層次、多元化、規範化、正規化高質量健康發展。專家學者匯聚探討前沿理念，頂流醫者無私奉獻真知灼見，用通俗易懂的方式讓與會者快速掌握，會議現場座無虛席，學術氛圍濃厚。
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_08.jpg"
    />
  </div>
</template>
<script>
export default {};
</script>
